<template>
  <el-dialog
    :title="
      lang_data == 1 ? '物流信息' : 'Logistics information' + [datainfo.code]
    "
    :visible.sync="expressInfoShow"
    width="400px"
    :before-close="cancelEvent"
    :close-on-click-modal="false"
  >
    <div class="modatitle">
      <div>
        <span v-if="datainfo">{{ datainfo.name }}：</span
        ><span class="seColor">{{
          datainfo ? datainfo.tracking_no : tracking_no
        }}</span>
      </div>
    </div>
    <div class="ContPadding">
      <expressInfo
        :express_order_id="datainfo ? datainfo.id : ''"
        :tracking_no="tracking_no"
      ></expressInfo>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelEvent" size="small">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import expressInfo from "./expressInfo";
export default {
  components: { expressInfo },
  name: "expressInfo_modal",
  props: ["datainfo", "tracking_no", "expressInfoShow"],
  mounted() {},
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
    };
  },
  methods: {
    cancelEvent() {
      this.$emit("cancelEvent");
    },
  },
};
</script>
<style lang="scss" scoped>
.modatitle {
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 20px;
}
.ContPadding {
  min-height: 250px;
  overflow: auto;
}
</style>